import React, { useState } from "react";
import { Asterisk, VisibleError } from "../Index";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { ValidEmail } from "../VisibleError/VisibleError";

const Contact = () => {
  const [loader, setLoader] = useState(false);
  const [validemail, setValidemail] = useState(false);
  const [fromDataAll, setFromDataAll] = useState({
    name: "",
    email: "",
    message: "",
    phNumber: "",
  });

  const [fromDataError, setFromDataError] = useState({
    name: false,
    email: false,
    message: false,
    phNumber: false,
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    let check = false;
    if (fromDataAll.name === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          name: true,
        };
      });
      check = true;
    }
    if (fromDataAll.email === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          email: true,
        };
      });
      check = true;
    } else if (!ValidEmail(fromDataAll.email)) {
      setValidemail(true);
      check = true;
    }
    if (fromDataAll.phNumber === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          phNumber: true,
        };
      });
      check = true;
    }
    if (fromDataAll.message === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          message: true,
        };
      });
      check = true;
    }
    if (check) return;

    setLoader(true);

    try {
      const payloade = {
        userNmae: fromDataAll.name,
        email: fromDataAll.email,
        message: fromDataAll.message,
        phNumber: fromDataAll.phNumber,
      };
      await emailjs
        .send(
          "service_i2yjdgp",
          "template_l1allno",
          payloade,
          "xRc6LTmLrpupjzZj0"
        )
        .then(
          (response) => {
            if (response?.status === 200) {
              setFromDataAll({
                name: "",
                email: "",
                message: "",
                phNumber: "",
              });
              toast.success("Form Submitted Successfully!");
            } else {
              toast.error(response);
            }
          },
          (error) => {
            console.log("FAILED... ", error);
            toast.error(error);
          }
        );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  const handelechange = (e, main = 11) => {
    let { name, value } = e.target;
    if (name === "phNumber") {
      value = value.slice(0, main);
    }
    setFromDataAll((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
    setFromDataError((pre) => {
      return {
        ...pre,
        [name]: false,
      };
    });
    setValidemail(false);
  };
  return (
    <div className="contact-section">
      <div className="container">
        <div className="row g-lg-4 gy-5 align-items-center">
          <div className="col-lg-5">
            <div className="contact-content">
              <div className="section-title white">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g>
                      <path d="M6.6304 0.338424C6.67018 -0.112811 7.32982 -0.112807 7.3696 0.338428L7.72654 4.38625C7.75291 4.68505 8.10454 4.83069 8.33443 4.63804L11.4491 2.02821C11.7963 1.73728 12.2627 2.20368 11.9718 2.55089L9.36197 5.66556C9.1693 5.89546 9.31496 6.24709 9.61374 6.27346L13.6615 6.6304C14.1128 6.67018 14.1128 7.32982 13.6615 7.3696L9.61374 7.72654C9.31496 7.75291 9.1693 8.10454 9.36197 8.33443L11.9718 11.4491C12.2627 11.7963 11.7963 12.2627 11.4491 11.9718L8.33443 9.36197C8.10454 9.1693 7.75291 9.31496 7.72654 9.61374L7.3696 13.6615C7.32982 14.1128 6.67018 14.1128 6.6304 13.6615L6.27346 9.61374C6.24709 9.31496 5.89546 9.1693 5.66556 9.36197L2.55089 11.9718C2.20368 12.2627 1.73729 11.7963 2.02822 11.4491L4.63804 8.33443C4.83069 8.10454 4.68504 7.75291 4.38625 7.72654L0.338424 7.3696C-0.112811 7.32982 -0.112807 6.67018 0.338428 6.6304L4.38625 6.27346C4.68505 6.24709 4.83069 5.89546 4.63804 5.66556L2.02821 2.55089C1.73728 2.20368 2.20368 1.73729 2.55089 2.02822L5.66556 4.63804C5.89546 4.83069 6.24709 4.68504 6.27346 4.38625L6.6304 0.338424Z"></path>
                    </g>
                  </svg>
                  Get in Touch
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g>
                      <path d="M6.6304 0.338424C6.67018 -0.112811 7.32982 -0.112807 7.3696 0.338428L7.72654 4.38625C7.75291 4.68505 8.10454 4.83069 8.33443 4.63804L11.4491 2.02821C11.7963 1.73728 12.2627 2.20368 11.9718 2.55089L9.36197 5.66556C9.1693 5.89546 9.31496 6.24709 9.61374 6.27346L13.6615 6.6304C14.1128 6.67018 14.1128 7.32982 13.6615 7.3696L9.61374 7.72654C9.31496 7.75291 9.1693 8.10454 9.36197 8.33443L11.9718 11.4491C12.2627 11.7963 11.7963 12.2627 11.4491 11.9718L8.33443 9.36197C8.10454 9.1693 7.75291 9.31496 7.72654 9.61374L7.3696 13.6615C7.32982 14.1128 6.67018 14.1128 6.6304 13.6615L6.27346 9.61374C6.24709 9.31496 5.89546 9.1693 5.66556 9.36197L2.55089 11.9718C2.20368 12.2627 1.73729 11.7963 2.02822 11.4491L4.63804 8.33443C4.83069 8.10454 4.68504 7.75291 4.38625 7.72654L0.338424 7.3696C-0.112811 7.32982 -0.112807 6.67018 0.338428 6.6304L4.38625 6.27346C4.68505 6.24709 4.83069 5.89546 4.63804 5.66556L2.02821 2.55089C1.73728 2.20368 2.20368 1.73729 2.55089 2.02822L5.66556 4.63804C5.89546 4.83069 6.24709 4.68504 6.27346 4.38625L6.6304 0.338424Z"></path>
                    </g>
                  </svg>
                </span>
                <h2>Have questions, feedback, or inquiries?</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Necessitatibus id ipsa, eaque ut corporis obcaecati quasi.
                  Voluptatem sit doloribus mollitia quos asperiores inventore
                  harum, autem omnis ratione dolore, expedita blanditiis!
                </p>
              </div>
              <div className="contact-area">
                <div className="hotline-area">
                  <div className="icon">
                    <FaPhoneVolume />
                  </div>
                  <div className="content">
                    <p className="mb-0">
                      <a href="tel:+91 9836160115">+91 9836160115</a>
                    </p>
                  </div>
                </div>
                <div className="hotline-area">
                  <div className="icon">
                    <MdMarkEmailRead />
                  </div>
                  <div className="content">
                    <p className="mb-0">
                      <a href="mailto:humayan.kabirtech@gmail.com">
                        humayan.kabirtech@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="hotline-area">
                  <div className="icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="content">
                    <p className="mb-0">
                      92, Fatullapur, Nimta Kolkata, West Bengal 700124, India
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="contact-form-wrap">
              <div className="contact-form-area">
                <form>
                  <div className="row">
                    <div className="col-lg-6 mb-20">
                      <div className="form-inner">
                        <label htmlFor="fullName">
                          Full Name <Asterisk />
                        </label>
                        <input
                          type="text"
                          name="name"
                          aria-label="fullName"
                          required="required"
                          onChange={handelechange}
                          value={fromDataAll.name}
                        />
                        <VisibleError
                          show={fromDataError.name}
                          name="full name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-20">
                      <div className="form-inner">
                        <label htmlFor="email">
                          E-mail <Asterisk />
                        </label>
                        <input
                          type="email"
                          name="email"
                          aria-label="email"
                          required="required"
                          onChange={handelechange}
                          value={fromDataAll.email}
                        />
                        {validemail && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            Please enter a valid email !
                          </span>
                        )}
                        <VisibleError
                          show={fromDataError.email}
                          name="e-mail"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-20">
                      <div className="form-inner">
                        <label htmlFor="email">
                          Phone No <Asterisk />
                        </label>
                        <input
                          type="number"
                          name="phNumber"
                          aria-label="phNumber"
                          required="required"
                          onChange={handelechange}
                          value={fromDataAll.phNumber}
                        />
                        <VisibleError
                          show={fromDataError.phNumber}
                          name="phone no"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-30">
                      <div className="form-inner">
                        <label htmlFor="message">
                          Message <Asterisk />
                        </label>
                        <textarea
                          name="message"
                          aria-label="message"
                          required="required"
                          onChange={handelechange}
                          value={fromDataAll.message}
                        ></textarea>
                        <VisibleError
                          show={fromDataError.message}
                          name="message"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-inner">
                        <button
                          className="submit"
                          disabled={loader}
                          onClick={sendEmail}
                        >
                          {loader ? (
                            <TailSpin
                              visible={true}
                              height="20"
                              width="50"
                              color="#fff"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                            />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
